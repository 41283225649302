import {api} from 'boot/axios';
import StoryApi from 'src/apps/stories/api';
import BlogApi from 'src/api/blog';
import {FEED_ITEM_TYPES} from 'src/apps/recommender/types';

export default {
  fetchAdvertUserRecommendations() {
    return api.get('recommender/adverts/')
  },

  fetchFeed(params: any | undefined = undefined, config: object | undefined = undefined) {
    if (params?.cat) {
      if (params.cat === 'stories') {
        return StoryApi.getStories()
      }
      if (params.cat === 'blogs') {
        return new Promise((resolve, reject) => {
          BlogApi.getBlogFeed().then((response) => {
            const data = response.data.articles
            const mockResponse = {
              'count': data.length,
              'page_size': data.length,
              'pages': 1,
              'next': null,
              'previous': null,
              'results': data,
            }
            resolve({data: mockResponse})
          }).catch((error) => {
            reject(error)
          })
        })
      }

      return Promise.reject()
    }
    return api.get('recommender/feed/', {params, ...config})
  },

  postFeedInteractionClick(itemId: string, itemType: FEED_ITEM_TYPES) {
    return api.post('recommender/feed/interactions/click/', {item_id: itemId, item_type: itemType})
  },

  postFeedInteractionImpression(itemId: string, itemType: FEED_ITEM_TYPES) {
    return api.post('recommender/feed/interactions/impression/', {item_id: itemId, item_type: itemType})
  },
}
