import {RouteRecordRaw} from 'vue-router';

export default [
  {
    path: '',
    component: () => import('layouts/AppMainLayout.vue'),
    meta: {noFooter: true, noFooterBar: true, hasBackBtn: true},
    children: [
      {
        path: '/stories/',
        component: () => import('src/apps/stories/pages/StoryListPage.vue'),
        name: 'stories',
      },
      {
        path: '/stories/:slug/',
        component: () => import('src/apps/stories/pages/StoryDetailPage.vue'),
        name: 'stories-detail',
      },
    ],
  }
] as RouteRecordRaw[]
