import {Cookies, Notify} from 'quasar';
import {api} from 'boot/axios';
import {useMainStore} from 'stores/main';
import {useSubscriptionStore} from 'src/apps/subscription/store';
import {updateAppNotificationPermission} from 'src/etc/app-utils';


let
  CapacitorGeolocation,
  CapacitorPreferences,
  CapacitorPushNotifications,
  CapacitorShare,
  CapacitorCore,
  CapacitorDevice,
  CapacitorCamera,
  CapacitorFilesystem,
  CapacitorDirectory,
  CapacitorBrowser,
  CapacitorApp,
  CapacitorLocalNotifications,
  CapacitorNetwork,
  CapacitorTrackingTransparency,
  CapacitorLiveUpdate,
  CapacitorInAppReview


export default (async ({ssrContext, router, store}) => {
  const mainStore = useMainStore(store)
  const subStore = useSubscriptionStore(store)

  const {Capacitor} = await import('@capacitor/core')
  const {LocalNotifications} = await import('@capacitor/local-notifications')
  const {Device} = await import('@capacitor/device')
  const {Camera} = await import('@capacitor/camera')
  const {Geolocation} = await import('@capacitor/geolocation')
  const {Preferences} = await import('@capacitor/preferences')
  const {PushNotifications} = await import('@capacitor/push-notifications')
  const {Share} = await import('@capacitor/share')
  const {Browser} = await import('@capacitor/browser')
  const {App} = await import('@capacitor/app')
  const {Filesystem, Directory} = await import('@capacitor/filesystem')
  const {Network} = await import('@capacitor/network')
  const {AppTrackingTransparency} = await import('capacitor-ios-app-tracking')
  const {LiveUpdate} = await import('@capawesome/capacitor-live-update')
  const {InAppReview} = await import('@capacitor-community/in-app-review')


  CapacitorGeolocation = Geolocation
  CapacitorPreferences = Preferences
  CapacitorPushNotifications = PushNotifications
  CapacitorShare = Share
  CapacitorCore = Capacitor
  CapacitorDevice = Device
  CapacitorCamera = Camera
  CapacitorFilesystem = Filesystem
  CapacitorDirectory = Directory
  CapacitorBrowser = Browser
  CapacitorApp = App
  CapacitorLocalNotifications = LocalNotifications
  CapacitorNetwork = Network
  CapacitorTrackingTransparency = AppTrackingTransparency
  CapacitorLiveUpdate = LiveUpdate
  CapacitorInAppReview = InAppReview

  const cookies = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies // otherwise we're on client

  // set token if in Preferences
  try {
    const {value} = await Preferences.get({key: 'token'})
    // check if value is empty string or set
    if (value) {
      cookies.set('token', value || '', {
        path: '/',
        secure: !process.env.DEV,
        sameSite: 'Strict',
      })
    }
  } catch (err) {
    console.error(err)
  }

  const addListeners = async () => {
    await LocalNotifications.addListener('localNotificationActionPerformed', async (/*notification*/) => {
      console.log('localNotificationActionPerformed event')
    })

    await PushNotifications.addListener('registration', async (token) => {
      console.debug('Registration token: ', token.value);
      const info = await Device.getInfo();
      const id = await Device.getId();
      const data = {
        name: info.name,
        registration_id: token.value.toString(),
        device_id: id.identifier ?? mainStore.deviceId,
        active: true,
        type: Capacitor.getPlatform(),
        permission_status: 'granted'
      }
      await api.post('devices/', data).then(() => {
        console.debug('Notifications granted')
      }).catch((err) => {
        console.error('Error:', err.message)
      })
    })

    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', async notification => {
      console.log('Push notification received: ', notification);

      const data = notification.data;
      console.log(`Data received: ${JSON.stringify(data)}`);

      if (data.type === 'new_message' && mainStore.isLoggedIn) {
        await mainStore.fetchUnreadMessagesCount()
        // dont show notification incoming when app is open
        await PushNotifications.removeAllDeliveredNotifications()

        Notify.create({
          caption: data?.body,
          message: data?.title,
          group: false,
          avatar: data.avatar,
          position: 'bottom-left',
          badgeStyle: {minWidth: '500px'},
          timeout: 10000,
          progress: true,
          multiLine: true,
          actions: [
            {label: 'Schließen', flat: true, size: 'md', color: 'grey-6'},
            {
              label: 'Zum Chat', flat: true, size: 'md', color: 'white', handler: () => {
                router.push({name: 'messenger-chat', params: {chatUuid: data?.chat_uuid}})
              }
            },
          ]
        })
      }
    });


    await PushNotifications.addListener('pushNotificationActionPerformed', async notification => {

      console.log('Push notification action performed', notification.toString());
      const data = notification.notification.data;

      if (!mainStore.accountDetail?.uuid) {
        await mainStore.fetchAccountDetails()
      }

      if (data.type === 'test') {
        mainStore.setSiteLoading(true)
        Notify.create(
          {
            message: JSON.stringify(data),
            color: 'info',
            timeout: 0,
            closeBtn: true
          }
        )
        mainStore.setSiteLoading(false)
      }

      if (data.type === 'new_message') {
        if (data.to_user === mainStore.accountDetail?.uuid) {
          if (data.business_slug) {
            console.debug(`Switched to business: ${data.business_slug}`)
          } else {
            console.debug(`Switched to user: ${mainStore.accountDetail?.public_name}`)
          }

          mainStore.setSiteLoading(true)
          mainStore.setActiveAccount(data?.business_slug).then(async () => {
            await router.push({name: 'messenger-chat', params: {chatUuid: data.chat_uuid}});
          }).finally(() => {
            mainStore.setSiteLoading(false)
          })
        } else {
          router.push({name: 'messenger'})
          mainStore.setSiteLoading(false)
        }
      }
    });

    await App.addListener('resume', function () {
      if (mainStore.isLoggedIn) {
        mainStore.fetchUnreadMessagesCount()
        mainStore.fetchAccountDetails()
        subStore.fetchAccountQuota()
        subStore.fetchAccountSubscription()
      }

      updateAppNotificationPermission()
      console.debug('App resumed')
    })
  }

  try {
    await addListeners()
  } catch (e) {
    console.warn('could not run push notification listeners\n', e)
  }
})

export {
  CapacitorPreferences,
  CapacitorGeolocation,
  CapacitorPushNotifications,
  CapacitorShare,
  CapacitorCore,
  CapacitorDevice,
  CapacitorCamera,
  CapacitorFilesystem,
  CapacitorDirectory,
  CapacitorBrowser,
  CapacitorApp,
  CapacitorLocalNotifications,
  CapacitorNetwork,
  CapacitorTrackingTransparency,
  CapacitorLiveUpdate,
  CapacitorInAppReview
}
