import {api} from 'boot/axios';

export default {
  getStory(uuid: string) {
    return api.get(`stories/${uuid}/`);
  },

  getStories() {
    return api.get('stories/');
  },

  reactToStory(emoji, messageId, textId) {
    return api.post('stories/react/', {emoji, message_id: messageId, text_index: textId});
  }
}
