import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center column items-center",
  style: {"gap":"24px"}
}
const _hoisted_2 = {
  class: "full-width flex column items-center",
  style: {"gap":"8px"}
}
const _hoisted_3 = { class: "full-width" }
const _hoisted_4 = { class: "full-width" }
const _hoisted_5 = {
  key: 1,
  class: "flex column",
  style: {"gap":"24px"}
}
const _hoisted_6 = {
  key: 0,
  class: "text-center q-mb-lg"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 2,
  class: "text-center"
}
const _hoisted_9 = {
  class: "flex justify-center",
  style: {"gap":"12px"}
}
const _hoisted_10 = {
  class: "full-width flex column items-center",
  style: {"gap":"8px"}
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "full-width" }
const _hoisted_13 = {
  key: 1,
  class: "full-width"
}

import {useDialogPluginComponent, useQuasar} from 'quasar'
import {computed, ref} from 'vue';
import {APPLE_STORE_URL, GOOGLE_PLAY_URL} from 'src/apps/core/static';
import CoreApi from 'src/api/core';



export default /*@__PURE__*/_defineComponent({
  __name: 'RateAppDialog',
  emits: [
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
],
  setup(__props) {

const rating = ref()
const step = ref(1)
const ratingText = ref('')
const $q = useQuasar()

const ratingColors = computed(() => {
  const colors = ['negative', 'orange', 'lime', 'positive']
  if (!rating.value) return colors

  return colors.map((color, index) => {
    return index + 1 === rating.value ? color : 'grey'
  })
})

const icons = [
  'sentiment_very_dissatisfied',
  'sentiment_dissatisfied',
  'sentiment_satisfied',
  'sentiment_very_satisfied'
]





const loadingBtn = ref(false)
const loadingSkipBtn = ref(false)
const isError = ref(false)


function onSubmit() {
  loadingBtn.value = true

  CoreApi.createUserRating({
    rating: rating.value,
    feedback: ratingText.value
  }).then(() => {
    onDialogOK({rating, feedback: ratingText.value})
    CoreApi.updateAskedTimes().catch(console.error)
  }).catch(err => {
    isError.value = true
    console.error(err)
  }).finally(() => {
    loadingBtn.value = false
  })
}

const {dialogRef, onDialogHide, onDialogOK, onDialogCancel} = useDialogPluginComponent()


function skip() {
  loadingSkipBtn.value = true
  CoreApi.updateAskedTimes().catch(console.error).finally(() => {
    onDialogCancel()
    loadingSkipBtn.value = false
  })
}

return (_ctx: any,_cache: any) => {
  const _component_q_rating = _resolveComponent("q-rating")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    position: "bottom",
    persistent: "",
    "no-shake": "",
    onHide: _unref(onDialogHide)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: "q-dialog-plugin relative-position rounded-borders",
        style: {"overflow":"visible"}
      }, {
        default: _withCtx(() => [
          _cache[17] || (_cache[17] = _createElementVNode("div", {
            class: "absolute full-width",
            style: {"top":"0","transform":"translateY(-50%)"}
          }, [
            _createElementVNode("div", { class: "full-width flex justify-center" }, [
              _createElementVNode("div", {
                class: "bg-amber-2 relative-position",
                style: {"border":"8px solid white","border-radius":"50%","aspect-ratio":"1 !important","width":"80px"}
              }, [
                _createElementVNode("div", {
                  class: "absolute absolute-center",
                  style: {"font-size":"2rem"}
                }, "🤩")
              ])
            ])
          ], -1)),
          _createVNode(_component_q_card_section, { style: {"margin-top":"25px"} }, {
            default: _withCtx(() => [
              (step.value === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-center" }, [
                      _createElementVNode("div", { class: "text-muted q-mb-sm" }, "Hilf uns uns zu verbessern"),
                      _createElementVNode("div", { class: "text-h4" }, "Wie gefällt dir Camper Trader?")
                    ], -1)),
                    _createElementVNode("div", null, [
                      _createVNode(_component_q_rating, {
                        modelValue: rating.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((rating).value = $event)),
                        max: 4,
                        size: "4em",
                        icon: icons,
                        "no-reset": "",
                        "no-dimming": "",
                        color: ratingColors.value
                      }, null, 8, ["modelValue", "color"])
                    ]),
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_q_btn, {
                          class: "full-width",
                          square: "",
                          disable: !rating.value,
                          color: rating.value ? 'tertiary' : 'grey',
                          unelevated: "",
                          "no-caps": "",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (step.value = 2))
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("Abschicken ")
                          ])),
                          _: 1
                        }, 8, ["disable", "color"])
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_q_btn, {
                          class: "full-width",
                          square: "",
                          "no-caps": "",
                          flat: "",
                          onClick: skip
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode("Überspringen")
                          ])),
                          _: 1
                        })
                      ])
                    ])
                  ]))
                : (step.value === 2)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (rating.value > 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[6] || (_cache[6] = [
                            _createElementVNode("div", { class: "text-h4" }, " Oh, wow! Vielen Dank! ", -1),
                            _createElementVNode("div", { class: "text-caption" }, "Wir sind sehr dankbar dich bei uns zu haben.", -1)
                          ])))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", null, [
                        _createVNode(_component_q_input, {
                          modelValue: ratingText.value,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ratingText).value = $event)),
                          type: "textarea",
                          outlined: "",
                          label: rating.value <= 2 ? 'Was können wir besser machen?' : 'Was gefällt dir besonders gut?'
                        }, null, 8, ["modelValue", "label"])
                      ]),
                      (rating.value <= 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _cache[8] || (_cache[8] = _createTextVNode(" Falls du weitere Hilfe benötigst kontaktiere uns gerne über unsere Kontaktseite. ")),
                            _createVNode(_component_router_link, {
                              target: "_blank",
                              to: {name: 'contact'}
                            }, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode("Hier klicken")
                              ])),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true),
                      (rating.value > 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-muted q-mb-sm" }, "Bitte unterstütze uns und die Community!", -1)),
                            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-h3 text-weight-light q-mb-sm" }, " Bewerte uns auch hier ", -1)),
                            _createElementVNode("div", _hoisted_9, [
                              (_unref($q).platform.is.nativeMobile && _unref($q).platform.is.android)
                                ? (_openBlock(), _createBlock(_component_q_btn, {
                                    key: 0,
                                    href: _unref(GOOGLE_PLAY_URL),
                                    target: "_blank",
                                    color: "dark",
                                    rounded: "",
                                    unelevated: "",
                                    "no-caps": ""
                                  }, {
                                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                                      _createTextVNode("Play Store ")
                                    ])),
                                    _: 1
                                  }, 8, ["href"]))
                                : _createCommentVNode("", true),
                              (_unref($q).platform.is.nativeMobile && _unref($q).platform.is.ios)
                                ? (_openBlock(), _createBlock(_component_q_btn, {
                                    key: 1,
                                    href: _unref(APPLE_STORE_URL),
                                    target: "_blank",
                                    color: "dark",
                                    rounded: "",
                                    unelevated: "",
                                    "no-caps": ""
                                  }, {
                                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                                      _createTextVNode("App Store ")
                                    ])),
                                    _: 1
                                  }, 8, ["href"]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_q_btn, {
                                href: "https://g.page/r/CSsP22gZcas1EBM/review",
                                target: "_blank",
                                color: "dark",
                                rounded: "",
                                unelevated: "",
                                "no-caps": ""
                              }, {
                                default: _withCtx(() => _cache[11] || (_cache[11] = [
                                  _createTextVNode("Google ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_q_btn, {
                                href: "https://de.trustpilot.com/review/campertrader.de",
                                target: "_blank",
                                color: "dark",
                                rounded: "",
                                unelevated: "",
                                "no-caps": ""
                              }, {
                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createTextVNode("Trustpilot ")
                                ])),
                                _: 1
                              })
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_10, [
                        (isError.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[15] || (_cache[15] = [
                              _createElementVNode("div", { class: "text-negative text-small" }, "Leider ist ein Fehler aufgetreten beim Absenden.", -1)
                            ])))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_q_btn, {
                            class: "full-width",
                            color: "tertiary",
                            loading: loadingBtn.value,
                            unelevated: "",
                            square: "",
                            "no-caps": "",
                            onClick: onSubmit
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(isError.value ? 'Nochmal versuchen' : 'Abschicken'), 1)
                            ]),
                            _: 1
                          }, 8, ["loading"])
                        ]),
                        (isError.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              _createVNode(_component_q_btn, {
                                class: "full-width",
                                flat: "",
                                unelevated: "",
                                square: "",
                                "no-caps": "",
                                onClick: _unref(onDialogHide)
                              }, {
                                default: _withCtx(() => _cache[16] || (_cache[16] = [
                                  _createTextVNode(" Später versuchen ")
                                ])),
                                _: 1
                              }, 8, ["onClick"])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onHide"]))
}
}

})